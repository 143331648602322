<template>
  <div class="row p-2 item-fee-card rounded border align-items-center">
    <div class="col-12 col-md-6 text-warning">
      {{ settings.text }}
    </div>
    <div class="col-4 col-md-2">
      <dx-util-number-box
        v-model="feeSettings.value"
        format="$ #,##0.##"
        :min="0"
        :step=".10"
        styling-mode="filled"
      />
    </div>
    <div class="col-4 col-md-2">
      <dx-util-check-box
        v-model="feeSettingsIndividual.value"
        class="align-self-center"
        text="Charge each individual item"
      />
    </div>
    <div class="col-4 col-md-2 text-right">
      <dx-util-button
        icon="save"
        text="Save"
        :hint="`Save ${feeSettings.key}`"
        type="default"
        styling-mode="contained"
        @click="save"
    />
    </div>
  </div>
</template>

<script>
import tenantService from '@/http/requests/tenant/tenan.settings'
import scopeTypeEnum from '@/enums/scopeTypeEnum'
import valueTypeEnum from '@/enums/valueTypeEnum'
import { Notify } from '@/@robustshell/utils'

export default {
  props: {
    settings: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      feeSettings: {
        id: null,
        key: this.settings.inputBox.key,
        value: 0,
        tenantId: null,
        scopeType: scopeTypeEnum.TENANTSCOPEBACKEND.value,
        valueType: valueTypeEnum.BIGDECIMAL.value,
        version: null,
        referenceId: null,
      },
      feeSettingsIndividual: {
        id: null,
        key: this.settings.checkBox.key,
        value: 0,
        tenantId: null,
        scopeType: scopeTypeEnum.TENANTSCOPEBACKEND.value,
        valueType: valueTypeEnum.BOOLEAN.value,
        version: null,
        referenceId: null,
      },
    }
  },
  mounted() {
    this.getAll()
  },
  methods: {
    async getAll() {
      const getPromises = [
        this.getFeeSettings(),
        this.getFeeSettingsIndividual(),
      ]
      try {
        await Promise.all(getPromises)
      } catch (error) {
        Notify.error('Some of the fee settings are not fetched.')
      }
    },
    async getFeeSettings() {
      tenantService.fetchByKey(this.feeSettings.key).then(result => {
        const data = { ...result.data }
        if (data.id) {
          this.feeSettings = {
            id: data.id,
            key: data.key,
            value: parseFloat(data.value),
            tenantId: data.tenantId,
            scopeType: scopeTypeEnum.TENANTSCOPEBACKEND.key,
            valueType: data.valueType,
            version: data.version,
            referenceId: data.referenceId,
          }
        }
      })
    },
    async getFeeSettingsIndividual() {
      tenantService.fetchByKey(this.feeSettingsIndividual.key).then(result => {
        const data = { ...result.data }
        if (data.id) {
          this.feeSettingsIndividual = {
            id: data.id,
            key: data.key,
            value: JSON.parse(data.value.toLowerCase()),
            tenantId: data.tenantId,
            scopeType: scopeTypeEnum.TENANTSCOPEBACKEND.key,
            valueType: data.valueType,
            version: data.version,
            referenceId: data.referenceId,
          }
        }
      })
    },
    async save() {
      const savePromises = [
        this.saveFeeSettings(),
        this.saveFeeSettingsIndividual(),
      ]
      try {
        await Promise.all(savePromises)
      } catch (error) {
        Notify.error('An error occurred during one or more save operations. Please save again.')
      }
    },
    async saveFeeSettings() {
      const feeSettings = { ...this.feeSettings }
      if (feeSettings.id) {
        const result = await tenantService.update(feeSettings)
        const { id, version, tenantId } = result.data.body
        this.feeSettings = {
          ...this.feeSettings,
          id,
          version,
          tenantId,
        }
      } else {
        const result = await tenantService.create(feeSettings)
        const { id, version, tenantId } = result.data.body
        this.feeSettings = {
          ...this.feeSettings,
          id,
          version,
          tenantId,
        }
      }
    },
    async saveFeeSettingsIndividual() {
      const feeSettingsIndividual = { ...this.feeSettingsIndividual }
      if (feeSettingsIndividual.id) {
        const result = await tenantService.update(feeSettingsIndividual)
        const { id, version, tenantId } = result.data.body
        this.feeSettingsIndividual = {
          ...this.feeSettingsIndividual,
          id,
          version,
          tenantId,
        }
      } else {
        const result = await tenantService.create(feeSettingsIndividual)
        const { id, version, tenantId } = result.data.body
        this.feeSettingsIndividual = {
          ...this.feeSettingsIndividual,
          id,
          version,
          tenantId,
        }
      }
    },
  },

}
</script>

<style lang="scss" scoped>
div.dark-layout .item-fee-card {
  background-color: rgba(30, 36, 49, 0.8);
  color: rgb(217, 219, 222);
}

div.light-layout .item-fee-card {
  background-color: rgba(196, 208, 224, 0.4);
  color: rgb(47, 68, 90);
}

div.bordered-layout .item-fee-card {
  background-color: rgba(196, 208, 224, 0.4);
  color: rgb(47, 68, 90);
}
</style>
