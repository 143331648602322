<template>
  <div class="row m-1">
    <div class="col-xl-6">
      <div class="card card-psh border">
        <div class="bg-light-primary rounded-top py-2 px-1">
          <div class="psh-header mb-0 d-flex align-items-center">
            <div class="mr-1 border-0">
              <p-icon name="bi-gear-fill" size="48px" color="primary" />
            </div>
            <div class="d-flex flex-column">
              <h4 class="card-title mb-25">
                Problem Handling Fee
              </h4>
              <p class="card-text mb-0">
                Configure Settings
              </p>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-12 mb-2">
              Set these values for problem resolution charge schedules. <b class="text-warning">Problem Charge</b> is used to set
              the charge amount per problem. When <b class="text-warning">Charge Is Per Item Count</b> is checked, the fee will be applied to
              per item count. For example, when a problem created for 5 items, the client will be charged for 5 Problem Charges if this value is checked
            </div>
          </div>
          <div v-for="settings in problemHandlingFees" :key="settings.id" class="py-half">
            <single-problem-fee-settings :settings="settings" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import problemHandlingKeysEnum from '@/enums/problemHandlingKeys'
import { v4 as uuidv4 } from 'uuid'
import SingleProblemFeeSettings from './SingleProblemFeeSettings.vue'

export default {
  components: {
    'single-problem-fee-settings': SingleProblemFeeSettings,
  },
  data() {
    return {
      problemHandlingFees: [
        {
          id: uuidv4(),
          text: 'Set the fee for handling damaged items',
          inputBox: {
            key: problemHandlingKeysEnum.PROBLEM_DAMAGED.value,
            title: problemHandlingKeysEnum.PROBLEM_DAMAGED.text,
          },
          checkBox: {
            key: problemHandlingKeysEnum.PROBLEM_DAMAGED_IND.value,
            title: problemHandlingKeysEnum.PROBLEM_DAMAGED_IND.text,
          },
        },
        {
          id: uuidv4(),
          text: 'Set the fee for handling mispicked or miss-shipped items',
          inputBox: {
            key: problemHandlingKeysEnum.PROBLEM_MISPICK_MISSHIP.value,
            title: problemHandlingKeysEnum.PROBLEM_MISPICK_MISSHIP.text,
          },
          checkBox: {
            key: problemHandlingKeysEnum.PROBLEM_MISPICK_MISSHIP_IND.value,
            title: problemHandlingKeysEnum.PROBLEM_MISPICK_MISSHIP_IND.text,
          },
        },
        {
          id: uuidv4(),
          text: 'Set the fee for handling items with alarm tags',
          inputBox: {
            key: problemHandlingKeysEnum.PROBLEM_ALARM_TAG.value,
            title: problemHandlingKeysEnum.PROBLEM_ALARM_TAG.text,
          },
          checkBox: {
            key: problemHandlingKeysEnum.PROBLEM_ALARM_TAG_IND.value,
            title: problemHandlingKeysEnum.PROBLEM_ALARM_TAG_IND.text,
          },
        },
        {
          id: uuidv4(),
          text: 'Set the fee for handling Hazmat items',
          inputBox: {
            key: problemHandlingKeysEnum.PROBLEM_HAZMAT.value,
            title: problemHandlingKeysEnum.PROBLEM_HAZMAT.text,
          },
          checkBox: {
            key: problemHandlingKeysEnum.PROBLEM_HAZMAT_IND.value,
            title: problemHandlingKeysEnum.PROBLEM_HAZMAT_IND.text,
          },
        },
        {
          id: uuidv4(),
          text: 'Set the fee for handling dirty or used items',
          inputBox: {
            key: problemHandlingKeysEnum.PROBLEM_DIRTY_OR_USED.value,
            title: problemHandlingKeysEnum.PROBLEM_DIRTY_OR_USED.text,
          },
          checkBox: {
            key: problemHandlingKeysEnum.PROBLEM_DIRTY_OR_USED_IND.value,
            title: problemHandlingKeysEnum.PROBLEM_DIRTY_OR_USED_IND.text,
          },
        },
        {
          id: uuidv4(),
          text: 'Set the fee for handling items with expiration date issues',
          inputBox: {
            key: problemHandlingKeysEnum.PROBLEM_EXPIRATION_DATE_ISSUE.value,
            title: problemHandlingKeysEnum.PROBLEM_EXPIRATION_DATE_ISSUE.text,
          },
          checkBox: {
            key: problemHandlingKeysEnum.PROBLEM_EXPIRATION_DATE_ISSUE_IND.value,
            title: problemHandlingKeysEnum.PROBLEM_EXPIRATION_DATE_ISSUE_IND.text,
          },
        },
        {
          id: uuidv4(),
          text: 'Set the fee for handling items where the customer changed their mind',
          inputBox: {
            key: problemHandlingKeysEnum.PROBLEM_CUSTOMER_CHANGED_MIND.value,
            title: problemHandlingKeysEnum.PROBLEM_CUSTOMER_CHANGED_MIND.text,
          },
          checkBox: {
            key: problemHandlingKeysEnum.PROBLEM_CUSTOMER_CHANGED_MIND_IND.value,
            title: problemHandlingKeysEnum.PROBLEM_CUSTOMER_CHANGED_MIND_IND.text,
          },
        },
        {
          id: uuidv4(),
          text: 'Set the fee for handling missing items',
          inputBox: {
            key: problemHandlingKeysEnum.PROBLEM_MISSING_ITEM.value,
            title: problemHandlingKeysEnum.PROBLEM_MISSING_ITEM.text,
          },
          checkBox: {
            key: problemHandlingKeysEnum.PROBLEM_MISSING_ITEM_IND.value,
            title: problemHandlingKeysEnum.PROBLEM_MISSING_ITEM_IND.text,
          },
        },
        {
          id: uuidv4(),
          text: 'Set the fee for handling items not delivered',
          inputBox: {
            key: problemHandlingKeysEnum.PROBLEM_NOT_DELIVERED.value,
            title: problemHandlingKeysEnum.PROBLEM_NOT_DELIVERED.text,
          },
          checkBox: {
            key: problemHandlingKeysEnum.PROBLEM_NOT_DELIVERED_IND.value,
            title: problemHandlingKeysEnum.PROBLEM_NOT_DELIVERED_IND.text,
          },
        },
        {
          id: uuidv4(),
          text: 'Set the fee for handling items with listing ASIN issues',
          inputBox: {
            key: problemHandlingKeysEnum.PROBLEM_LISTING_ASIN_ISSUE.value,
            title: problemHandlingKeysEnum.PROBLEM_LISTING_ASIN_ISSUE.text,
          },
          checkBox: {
            key: problemHandlingKeysEnum.PROBLEM_LISTING_ASIN_ISSUE_IND.value,
            title: problemHandlingKeysEnum.PROBLEM_LISTING_ASIN_ISSUE_IND.text,
          },
        },
        {
          id: uuidv4(),
          text: 'Set the fee for handling items with ASIN input errors',
          inputBox: {
            key: problemHandlingKeysEnum.PROBLEM_ASIN_INPUT_ERROR.value,
            title: problemHandlingKeysEnum.PROBLEM_ASIN_INPUT_ERROR.text,
          },
          checkBox: {
            key: problemHandlingKeysEnum.PROBLEM_ASIN_INPUT_ERROR_IND.value,
            title: problemHandlingKeysEnum.PROBLEM_ASIN_INPUT_ERROR_IND.text,
          },
        },
        {
          id: uuidv4(),
          text: 'Set the fee for handling item mismatches',
          inputBox: {
            key: problemHandlingKeysEnum.PROBLEM_ITEM_MISMATCH.value,
            title: problemHandlingKeysEnum.PROBLEM_ITEM_MISMATCH.text,
          },
          checkBox: {
            key: problemHandlingKeysEnum.PROBLEM_ITEM_MISMATCH_IND.value,
            title: problemHandlingKeysEnum.PROBLEM_ITEM_MISMATCH_IND.text,
          },
        },
        {
          id: uuidv4(),
          text: 'Set the fee for handling items with missing data entry',
          inputBox: {
            key: problemHandlingKeysEnum.PROBLEM_MISSING_ITEM_DATA_ENTRY.value,
            title: problemHandlingKeysEnum.PROBLEM_MISSING_ITEM_DATA_ENTRY.text,
          },
          checkBox: {
            key: problemHandlingKeysEnum.PROBLEM_MISSING_ITEM_DATA_ENTRY_IND.value,
            title: problemHandlingKeysEnum.PROBLEM_MISSING_ITEM_DATA_ENTRY_IND.text,
          },
        },
      ],
    }
  },
}
</script>

<style>

</style>
